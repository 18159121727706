.cb-container {
  display: block;
  position: relative;
  padding-left: 34px;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $background-white;
    border: 2px solid $color-gray-200;
    border-radius: 4px;
}
.cb-container input:checked ~ .checkmark {
  border: 2px solid $color-gray-200;
}
.cb-container .text-small
{
	color: $color-gray-500;
  font-weight: 500;
}
.cb-container input:checked ~ .text-small
{
	color: $color-gray-500;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.cb-container input:checked ~ .checkmark:after {
  display: block;
}
.cb-container input:checked ~ .text-lbl
{
	color: #ccc;
}
.cb-container .checkmark:after {
  left: -2px;
  top: -2px;
  width: 24px;
  height: 24px;
  background: $color-success url(../imgs/page/register/check.svg) no-repeat center;
  border-radius: 4px;
}
