.box-banner-home8 {
    background: url(../imgs/page/homepage8/bg.jpg) no-repeat top center;
    background-size: cover;
    padding: 120px 0px 20px 0px;
}
.banner-8 {
    position: relative;
}
.asset-1 {
    position: absolute;
    top: 90px;
    left: 100px;
    height: 97px;
    width: 86px;
    background: url(../imgs/page/homepage8/asset1.png) no-repeat center;
    background-size: contain;
}
.asset-3 {
    position: absolute;
    top: 90px;
    right: 350px;
    height: 66px;
    width: 66px;
    background: url(../imgs/page/homepage8/asset3.png) no-repeat center;
    background-size: contain;
}
.asset-2 {
    position: absolute;
    top: 300px;
    left: 100px;
    height: 75px;
    width: 75px;
    background: url(../imgs/page/homepage8/asset2.png) no-repeat center;
    background-size: contain;
}
.asset-4 {
    position: absolute;
    top: 340px;
    right: 100px;
    height: 53px;
    width: 59px;
    background: url(../imgs/page/homepage8/asset4.png) no-repeat center;
    background-size: contain;
}
.asset-5 {
    position: absolute;
    top: 440px;
    left: 350px;
    height: 226px;
    width: 215px;
    background: url(../imgs/page/homepage8/asset5.png) no-repeat center;
    background-size: contain;
}
.box-item-comment {
    display: flex;
    .image-comment {
        min-width: 130px;
        margin-right: 30px;
        img {
            width: 100%;
            border-radius: 16px;
        }
    }
    .info-comment {
        position: relative;
        .comment-quote {
            padding-right: 40px;
            background: url(../imgs/page/homepage8/quote.png) no-repeat top right;
            max-width: 375px;
        }
    } 
}
.box-phones {
    display: inline-block;
    max-width: 100%;
    position: relative;
    background: url(../imgs/page/homepage8/bg-img.png) no-repeat bottom center;
    background-size: contain;
    .box-phones-inner {
        display: flex;
        max-width: 100%;
        padding: 0px 30px;
        align-items: flex-end;
        img {
            position: relative;
            z-index: 2;
            display: block;
        }
    }
}
.box-our-app {
    padding-left: 40px;
}
.box-number-2 {
    max-width: 254px;
    width: 100%;
    padding: 30px 0px;
    text-align: center;
    box-shadow: $box-shadow-1;
    display: inline-block;
    border: 1px solid $color-white;
    border-radius: 8px;
}
.box-charts {
    display: flex;
    margin: 0px -8px;
    align-items: flex-end;
    .box-chart-1 {
        max-width: 380px;
        width: 100%;
        padding: 0px 8px;
        margin-bottom: 16px;
    }
    .box-chart-2 {
        max-width: 325px;
        width: 100%;
        padding: 0px 8px;
        margin-bottom: 16px;
    }
    .item-chart-inner {
        border-radius: 8px;
        padding: 0px;
        border: 1px solid $color-gray-80;
        overflow: hidden;
        box-shadow: $box-shadow-1;
    }
    .box-reviews {
        padding: 0px 8px;
        width: 100%;
        .item-chart-inner {
            padding: 30px 30px 10px 30px;
            .col-lg-6 {
                padding: 0px 8px;
            }
        }
    }
}