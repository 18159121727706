.icon-16 {
    height: 16px;
    color: $color-gray-900;
}
.banner-1 {
    padding: 80px 0px 70px 0px;
}
.banner-2 {
    background: url(../imgs/page/homepage2/bg-banner.png) no-repeat right center;
}
.banner-3 {
    background-color: $background-bg-8;
    .banner-1 {
        padding-bottom: 0px;
    }
    .img-banner-1 {
        width: 53%;
    }
    .img-banner-2 {
        width: 47%;
    }
    // .image-banner-main {
    //     // width: 100%;
    //     // max-width: none;
    //     // position: absolute;
    //     // right: 40px;
    //     // bottom: 0px;
    // }
    .image-chart {
        position: absolute;
        bottom: 30px;
        right: -60px;
        z-index: 3;
    }
    .box-image-main {
        position: relative;
        height: 100%;
        width: 100%;
        &::before {
            content: "";
            height: 100%;
            width: 100%;
            background: url(../imgs/page/homepage3/bg-banner.png) no-repeat center;
            background-size: contain;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
        }
        img {
            position: relative;
            z-index: 2;
            left: -70px;
        }
    }
}
.banner-4 {
    background: $color-brand-2 url(../imgs/page/homepage4/finger.png) no-repeat top right;
    position: relative;
    .container {
        position: relative;
        z-index: 2;
    }
    &::before {
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 150px;
        width: 133px;
        z-index: 1;
        content: "";
        background: url(../imgs/page/homepage4/finger-2.png) no-repeat bottom left;
        background-size: contain;
    }
}
.banner-about {
    background: url(../imgs/page/about/finger.png) no-repeat top 10px right 20px;
}
@keyframes hero-thumb-animation {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes hero-thumb-animation-2 {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-20px);
    }
}
@keyframes hero-thumb-sm-animation {
    0% {
        -webkit-transform: translateY(-20px) translateX(50px);
        -moz-transform: translateY(-20px) translateX(50px);
        -ms-transform: translateY(-20px) translateX(50px);
        transform: translateY(-20px) translateX(50px);
    }
    100% {
        -webkit-transform: translateY(-20px) translateX(0px);
        -moz-transform: translateY(-20px) translateX(0px);
        -ms-transform: translateY(-20px) translateX(0px);
        transform: translateY(-20px) translateX(0px);
    }
}
@keyframes hero-thumb-sm-2-animation {
    0% {
        -webkit-transform: translateY(-50px);
        -moz-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
    }
    100% {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
    }
}
.shape-1 {
    -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
    -moz-animation: hero-thumb-animation 2s linear infinite alternate;
    -o-animation: hero-thumb-animation 2s linear infinite alternate;
    animation: hero-thumb-animation 2s linear infinite alternate;
}
.shape-1-2 {
    -webkit-animation: hero-thumb-animation-2 2s linear infinite alternate;
    -moz-animation: hero-thumb-animation-2 2s linear infinite alternate;
    -o-animation: hero-thumb-animation-2 2s linear infinite alternate;
    animation: hero-thumb-animation-2 2s linear infinite alternate;
}
.shape-2 {
    -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-animation 4s linear infinite alternate;
}
.shape-3 {
    -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}
// End New Agon