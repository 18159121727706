/*RESET*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}
main {
	display: block;
	clear: both;
}
thead {
    font-weight: 600;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
img {
    max-width: 100%;
}
input,
select,
button,
textarea {
    font-family: $font-manrope;
    font-size: $font-sm;
}
*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}
input:focus::-moz-placeholder {
    opacity: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

li.hr {
    span {
        width: 100%;
        height: 1px;
        background-color: #e4e4e4;
        margin: 20px 0;
        display: block;
    }
}
/*--- Common Classes---------------------*/
::selection {
    background: #069; /* WebKit/Blink Browsers */
    color: #fff;
}
::-moz-selection {
    background: #069; /* Gecko Browsers */
    color: #fff;
}
::placeholder {
    color: $color-gray-800;
    opacity: 1;
}
:-ms-input-placeholder,
::-webkit-input-placeholder {
    color: $color-gray-800;
    opacity: 1;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
    outline: none !important;
    box-shadow: none;
}
.img-responsive {
    max-width: 100%;
}
// New Ecom
body
{
    font-family: $font-manrope;
}
h1, h2, h3, h4, h5, h6 {
    font-family: $font-manrope;
    font-style: normal;
    color: #000000;
}
h1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 72px;
}
h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
}
h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
}
h4 {
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
}
h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}
h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}
.bg-brand-1 {
    background-color: $color-brand-1;
}
.bg-brand-2 {
    background-color: $color-brand-2 !important;
}
.bg-grey-80 {
    background-color: $color-gray-80;
}
.bg-grey-60 {
    background-color: $color-gray-60 !important;
}
.bg-white {
    background-color: $background-white;
}
.border-grey-80 {
    border-color: $color-gray-80 !important;
}
.color-brand-1 {
    color: $color-brand-1 !important;
}
.color-brand-2 {
    color: $color-brand-2 !important;
}
.color-brand-3 {
    color: $color-brand-3;
}
.color-brand-4 {
    color: $color-brand-4;
}
.color-grey-50 {
    color: $color-gray-50;
}
.color-grey-80 {
    color: $color-gray-80;
}
.color-grey-100 {
    color: $color-gray-100;
}
.color-grey-200 {
    color: $color-gray-200;
}
.color-grey-300 {
    color: $color-gray-300;
}
.color-grey-400 {
    color: $color-gray-400;
}
.color-grey-500 {
    color: $color-gray-500;
}
.color-grey-600 {
    color: $color-gray-600;
}
.color-grey-700 {
    color: $color-gray-700;
}
.color-grey-800 {
    color: $color-gray-800;
}
.color-grey-900 {
    color: $color-gray-900;
}
.font-3xl {
    font-weight: 500;
    font-family: $font-manrope;
    line-height: 36px;
    font-size: 30px;
}
.font-2xl {
    font-weight: 500;
    font-family: $font-manrope;
    line-height: 32px;
    font-size: 24px;
}
.font-xl {
    font-weight: 500;
    font-family: $font-manrope;
    line-height: 28px;
    font-size: 20px;
}
.font-lg {
    font-weight: 500;
    font-family: $font-manrope;
    line-height: 23px;
    font-size: 18px;
}
.font-md {
    font-weight: 500 !important;
    font-family: $font-manrope !important;
    line-height: 24px !important;
    font-size: 16px !important;
}
.font-sm {
    font-weight: 500;
    font-family: $font-manrope;
    line-height: 18px;
    font-size: 14px;
}
.font-xs {
    font-weight: 500;
    font-family: $font-manrope;
    line-height: 18px;
    font-size: 12px;
}
.font-3xl-bold {
    font-weight: 700;
    font-family: $font-manrope;
    line-height: 36px;
    font-size: 30px;
}
.font-2xl-bold {
    font-weight: 700;
    font-family: $font-manrope;
    line-height: 32px;
    font-size: 24px;
}
.font-xl-bold {
    font-weight: 700;
    font-family: $font-manrope;
    line-height: 28px;
    font-size: 20px;
}
.font-lg-bold {
    font-weight: 700;
    font-family: $font-manrope;
    line-height: 23px;
    font-size: 18px;
}
.font-md-bold {
    font-weight: 700;
    font-family: $font-manrope;
    line-height: 24px;
    font-size: 16px;
}
.font-sm-bold {
    font-weight: 700 !important;
    font-family: $font-manrope !important;
    line-height: 18px !important;
    font-size: 14px !important;
}
.font-xs-bold {
    font-weight: 700;
    font-family: $font-manrope;
    line-height: 18px;
    font-size: 12px;
}
.color-grey-900 {
    color: $color-gray-900 !important;
}
.color-success {
    color: $color-success;
}
.color-warning {
    color: $color-warning;
}
.color-danger {
    color: $color-danger;
}
.color-mutted {
    color: $color-mutted;
}
.color-info {
    color: $color-info;
}
.color-white {
    color: $color-white !important;
}
.section {
    display: inline-block;
    width: 100%;
}
.bg-1 {
    background-color: $background-bg-1 !important;
}
.bg-2 {
    background-color: $background-bg-2 !important;
}
.bg-3 {
    background-color: $background-bg-3 !important;
}
.bg-4 {
    background-color: $background-bg-4 !important;
}
.bg-5 {
    background-color: $background-bg-5 !important;
}
.bg-7 {
    background-color: $background-bg-7 !important;
}
.bg-8 {
    background-color: $background-bg-8 !important;
}
.bg-10 {
    background-color: $background-bg-10 !important;
}
.bg-11 {
    background-color: $background-bg-11 !important;
}
a {
    color: $color-gray-900;
    text-decoration: none;
    &:hover {
        color: $color-success;
    }
}
@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1410px;
    }
}
.hover-up {
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    &:hover {
        transform: translateY(-2px);
        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    }
}
.bd-rd4 {
    border-radius: 4px;
}
.bd-rd8 {
    border-radius: 8px;
}
.bd-rd16 {
    border-radius: 16px;
}
.form-control {
    height: 54px;
    border-radius: 8px;
    color: $color-gray-500;
    border: 1px solid $color-gray-80;
    padding: 18px 20px;
    &:focus {
        border-color: $color-success;
    }
}
.form-control::-moz-placeholder {
  color: $color-gray-200;
  font-size: 16px;
  line-height: 24px;
  opacity: 1;
}

.form-control::placeholder {
  color: $color-gray-200;
  font-size: 16px;
  line-height: 24px;
  opacity: 1;
}

.form-control:-ms-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: $color-gray-200;
  font-size: 16px;
  line-height: 24px;
  opacity: 1;
}
.font-shippori {
    font-family: $font-shippori;
}
.table-responsive {
    max-width: 100%;
}
.font-84 {
    font-size: 84px !important;
    line-height: 84px !important;
}
.box-404 {
    padding: 150px 0px 0px 0px;
}
.font-bold-800 {
    font-weight: 800;
}
.box-pagination {
    display: inline-block;
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    margin-bottom: 20px;
}
.pagination li {
    padding: 2px;
}
.pagination li a {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $color-brand-1;
    border-radius: 4px;
    border: 1px solid $color-gray-80;
    min-width: 36px;
    text-align: center;
}
.pagination li .page-prev {
    background: url(../imgs/page/shop/prev.svg) no-repeat center;
    min-height: 37px;
    padding: 5px 20px;
    border: 0px;
}
.pagination li .page-next {
    background: url(../imgs/page/shop/next.svg) no-repeat center;
    min-height: 37px;
    padding: 5px 20px;
    border: 0px;
}
.pagination li a:hover, .pagination li a.active {
    background-color: $color-brand-1;
    border-color: $color-brand-1;
    color: $color-gray-80;
}
.pagination li .page-next:hover {
    background-color: transparent;
}
.pagination li .page-prev:hover {
    background-color: transparent;
}
.rating img {
    width: 12px;
}
.box-count
{
    text-align: left;
    margin: 0px 0px 20px 0px;
    .deals-countdown
    {
        border-radius: 0px;
        display: flex;
        padding: 4px 0px;
        width: 100%;
        margin: auto;
        max-width: 100%;
        .countdown-section
        {
            display: inline-block;
            text-align: center;
            width: 25%;
            line-height: 12px;
            position: relative;
            span
            {
                display: block;
                color: $color-white;
            }
            .countdown-period
            {
                text-transform: capitalize;
            }
            &:last-child
            {
                &::before
                {
                    display: none;
                }
            }
        }
    }
    &.box-count-square
    {
        margin-bottom: 5px;
        .deals-countdown
        {
            background-color: $background-white;
            width: 100%;
            margin: auto;
            text-align: left;
            display: flex;
            max-width: 100%;
            .countdown-section
            {
                display: inline-block;
                text-align: center;
                line-height: 12px;
                position: relative;
                margin: 0px 16px 0px 0px;
                width: auto;
                .countdown-period
                {
                    color: $color-gray-500;
                    font-size: 12px !important;
                    line-height: 24px !important;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
            .countdown-amount
            {
                background-color: $color-gray-60;
                border: 1px solid $color-gray-80;
                border-radius: 4px;
                color: $color-brand-1;
                padding: 10px 0px;
                width: 78px;
                height: 80px;
                display: inline-block;
                margin-bottom: 5px;
                font-size: 32px !important;
                line-height: 55px !important;
            }
        }
    }
}