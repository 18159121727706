.footer {
    // New Agon
    .width-20
    {
        width: 20%;
    }
    .width-25
    {
        width: 25%;
    }
    .width-22
    {
        width: 22%;
    }
    .width-23
    {
        width: 23%;
    }
    .width-16
    {
        width: 19%;
    }

    .menu-footer {
        display: inline-block;
        li {
            list-style: none;
            margin-bottom: 8px;
            a {
                color: $color-gray-400;
                font-size: $font-md;
                font-weight: 500;
                line-height: 24px;
                text-decoration: none;
                display: block;
                padding: 0px 0px 0px 0px;
                transition-duration: 0.2s;
                &:hover {
                    color: $color-brand-1;
                    transition-duration: 0.2s;
                    padding-left: 2px;
                }
            }
        }
    }
    .footer-bottom {
        border-top: 1px solid $color-gray-300;
        padding: 30px 0px;
        color: $color-gray-500;
        font-size: $font-sm;
        a {
            text-decoration: none;
        }
    }
    // End new Agon
}
#scrollUp {
	width: 56px;
    height: 56px;
    color: $color-white;
    right: 20px;
    bottom: 20px;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    z-index: 999 !important;
    border: 0;
    background-color: #fff;
    transition-duration: 0.2s;
    background-color: $color-brand-1;
    i {
        display: block;
        line-height: 56px !important;
        font-size: 20px;
        color: #fff;
    }
    &:hover {
        transform: translateY(-3px);
        transition-duration: 0.2s;
        background-color: $color-brand-1-hover;
    }
}
.menu-bottom
{
    li
    {
        display: inline-block;
        a
        {
            display: block;
            padding:0px 15px;
        }
        &:first-child
        {
            a
            {
                padding-left: 0px;
            }
        }
    }
}
.footer-1
{
    background-color: $color-white;
    margin: 0px 0px 0px 0px;
    padding: 90px 0px 25px 0px;
}
.footer-3 {
    border-top: 1px solid $color-gray-300;
    padding-top: 30px;
}

.box-newsletter {
    position: relative;
    border-radius: 8px;
    background-color: $color-gray-80;
    padding: 40px;
    display: inline-block;
    width: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100px;
        width: 111px;
        background: url(../imgs/template/newsletter_finger_top.png) no-repeat top right;
        background-size: cover;
    }
    .img-main {
        border-radius: 565px;
        max-width: 100%;
        width: 100%;
    }
    .image-1 {
        position: absolute;
        left: 0px;
        bottom: -45px;
    }
    .box-image-newsletter {
        position: relative;
    }
    .form-newsletter {
        padding: 10px;
        background: $color-white;
        border-radius: 8px;
        form {
            display: flex;
            input {
                width: 100%;
                border: 0px;
                outline: 0;
                box-shadow: none;
                padding: 10px;
                color: $color-gray-900;
            }
        }
    }
    &.box-newsletter-2 {
        background-color: $background-white;
        border: 1px solid $color-brand-3;
        padding-bottom: 50px;
        background-image: url(../imgs/page/homepage3/tree.png);
        background-repeat: no-repeat;
        background-size: auto 80%;
        background-position: bottom right;
        .form-newsletter {
            border: 1px solid $color-gray-80;
            border-radius: 8px;
        }
        > .row {
            position: relative;
            z-index: 2;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 140px;
            height: 70px;
            width: 165px;
            background: url(../imgs/page/homepage3/ellipse1.png) no-repeat top right;
            background-size: contain;
            z-index: 1;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            z-index: 1;
            left: 60px;
            height: 50px;
            width: 346px;
            background: url(../imgs/page/homepage3/ellipse2.png) no-repeat top right;
            background-size: contain;
        }
    }
}
.box-footer-2 {
    background-color: $color-gray-80;
    border-radius: 8px;
    padding: 40px 30px 30px 30px;
}
.footer-home2 {
    padding: 0px;
    .footer-1 {
        padding-bottom: 0px;
    }
}
footer a {
    text-decoration: none;
}
.icon-socials {
    display: inline-block;
    margin-right: 8px;
    height: 21px;
    width: 21px;
    transition-duration: 0.2s;
    vertical-align: middle;
    background-size: contain !important;
    &:hover {
        transform: translateY(-3px);
        transition-duration: 0.2s;
    }
    &.icon-facebook {
        background: url(../imgs/template/icons/fb.svg) no-repeat 0px 0px;
        transition-duration: 0.2s;
        &:hover {
            background: url(../imgs/template/icons/fb.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
        }
    }
    &.icon-twitter {
        background: url(../imgs/template/icons/tw.svg) no-repeat 0px 0px;
        transition-duration: 0.2s;
        &:hover {
            background: url(../imgs/template/icons/tw.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
        }
    }
    &.icon-instagram {
        background: url(../imgs/template/icons/inst.svg) no-repeat 0px 0px;
        transition-duration: 0.2s;
        &:hover {
            background: url(../imgs/template/icons/inst.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
        }
    }
    &.icon-linkedin {
        background: url(../imgs/template/icons/in.svg) no-repeat 0px 0px;
        transition-duration: 0.2s;
        &:hover {
            background: url(../imgs/template/icons/in.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
        }
    }
    &.icon-youtube {
        background: url(../imgs/template/icons/youtube.svg) no-repeat 0px 0px;
        transition-duration: 0.2s;
        &:hover {
            background: url(../imgs/template/icons/youtube.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
        }
    }
}