.line-login {
    height: 8px;
    display: inline-block;
    width: 100%;
    background: url(../imgs/page/login/line.png) no-repeat top left;
    background-size: contain;
}
.box-banner-login {
    max-width: 450px;
    margin: auto;
}
.banner-login {
    .box-banner-abs {
        top: 40%;
        transform: translateY(-40%);
    }
}