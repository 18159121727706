.img-round-top-small {
    border-radius: 363px 363px 0 0;
    display: inline-block;
}
.box-imgs-branding {
    .img-round-top {
        display: inline-block;
    }
}
.box-imgs-branding {
    position: relative;
}
.img-branding-small {
    position: absolute;
    left: 0px;
    max-width: 50%;
    bottom: -20px;
}
.box-banner-home10 {
    padding: 70px 0px 50px 0px;
}
.list-buttons-circle {
    border-bottom: 0px;
    margin-bottom: 90px;
    li {
        a {
            border-radius: 50px !important;
            background-color: $color-gray-60 !important;
            border: 1px solid $color-gray-80 !important;
            color: $color-gray-500 !important;
            font-size: 20px !important;
            line-height: 28px !important;
            font-weight: bold !important;
            padding: 24px 32px !important;
            &.active {
                color: $color-brand-1 !important;
                background-color: $color-brand-2 !important;
                border: 1px solid $color-brand-1 !important;
            }
        }
    }
}
.box-tab-32 {
    border-radius: 32px;
    background-color: $background-white;
    padding: 55px 65px;
    img {
        width: 100%;
    }
}
.box-business-tab {
    padding: 0px 0px 0px 30px;
}
.bg-explore {
    position: relative;
    .container {
        position: relative;
        z-index: 2;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        z-index: 1;
        background-image: url(../imgs/page/homepage10/grid.svg);
        background-repeat: no-repeat;
        background-size: cover;
    }
}