.icon-read {
    display: inline-block;
    padding: 0px 0px 0px 20px;
    margin-left: 20px;
    background: url(../imgs/page/blog/dot.svg) no-repeat left center;
}
.lbl-border {
    position: absolute;
    top: 17px;
    right: 18px;
    background-color: $background-white;
    border-radius: 4px;
    border: 1px solid $color-brand-1;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
}
.title-ads {
    font-size: 32px;
    line-height: 35px;
    font-weight: 800;
}
.box-ads-1 {
    max-width: 70%;
    border: 1px solid $color-gray-800;
    padding: 30px 40px;
    margin: auto;
    background-color: $background-bg-9;
    background-image: url(../imgs/page/blog/bg-free.png);
    background-repeat: no-repeat;
    background-position: right bottom;
}
.list-buttons {
    display: inline-block;
    width: 100%;
    text-align: center;
    li {
        display: inline-block;
        margin: 0px 4px 8px 4px;
        a {
            display: inline-block;
            padding: 11px 22px;
            background-color: $background-white;
            border: 1px solid $color-gray-80;
            border-radius: 8px;
            color: $color-gray-500;
            text-decoration: none;
            font-size: 16px;
            line-height: 24px;
            &:hover, &.active {
                background-color: $color-brand-1;
                border: 1px solid #024430;
                color: $color-brand-2;
            }
        }
    }
}