.box-banner-help {
    display: flex;
    margin: 0px -10px;
    padding-left: 70px;
    align-items: flex-end;
    position: relative;
    .banner-img-1 {
        padding: 0px 10px;
    }
    .banner-img-2 {
        padding: 0px 10px;
    }
}
.box-cruelty {
    position: absolute;
    top: -30px;
    right: -40px;
    z-index: 2;
}
.swiper-style-2 {
    .box-button-slider-bottom {
        position: unset;
    }
    .box-button-slider-bottom .swiper-button-prev-group-4 {
        position: absolute;
        top: 33%;
        left: -70px;
    }
    .box-button-slider-bottom .swiper-button-next-group-4 {
        position: absolute;
        top: 33%;
        right: -70px;
    }
}
.box-radius-border {
    &.box-radius-border-help {
        border-radius: 8px;
        padding: 0px;
        overflow: hidden;
        box-shadow: none;
    }
}
.box-info-answer {
    padding-left: 30px;
}
.table-forum {
    margin-bottom: 0px;
    min-width: 900px;
    thead {
        th {
            background-color: $background-bg-4;
            padding: 24px 30px 14px 30px;
            vertical-align: bottom;
            text-align: center;
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: left;
            }
        }
    }
    tbody {
        tr {
            td {
                border-bottom: 1px solid $color-gray-80;
                vertical-align: middle;
                padding: 30px;
                font-size: 18px;
                color: $color-gray-500;
                line-height: 23px;
                text-align: center;
                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: left;
                }
                .box-author img {
                    height: 42px;
                    width: 42px;
                }
                .author-name {
                    line-height: 18px;
                }
            }
            &:last-child {
                td {
                    border-bottom: 0px;
                }
            }
        }
    }
}
.table-box-help {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid $color-gray-80;
}
.width-16 {
    width: 16%;
}
.width-18 {
    width: 18%;
}
.width-50 {
    width: 50%;
}
.item-forum {
    display: flex;
    .item-image {
        height: 84px;
        width: 104px;
        border-radius: 8px;
        background-color: $color-gray-80;
        text-align: center;
        line-height: 64px;
        margin-right: 30px;
        padding: 10px;
        img {
            display: inline-block;
            vertical-align: middle;
            max-height: 52px;
        }
    }
}