.box-signup {
    background-color: $background-white;
    border-radius: 8px;
    box-shadow: $box-shadow-2;
    padding: 30px;
    max-width: 449px;
    margin: 0px 0px 0px auto;
    position: relative;
    z-index: 2;
    .btn.btn-brand-1-full {
        border-radius: 8px;
        color: $color-white;
        &:hover {
            color: $color-brand-1;
        }
    }
}
.arrow-down-banner {
    position: absolute;
    width: 33px;
    height: 138px;
    background: url(../imgs/page/homepage4/arrow-down.png) no-repeat center;
    top: 40%;
    left: -70px;
    z-index: 1;
}
.arrow-right-banner {
    position: absolute;
    width: 70px;
    height: 24px;
    background: url(../imgs/page/homepage4/arrow-right.png) no-repeat center;
    bottom: 0px;
    left: calc(100% + 20px);
    z-index: 1;
}
.signin-google {
    img {
        vertical-align: middle;
        display: inline-block;
        margin-right: 10px;
    }
}
.text-already {
    a {
        text-decoration: none;
    }
}
.form-cb {
    float: left;
    margin: 2px 7px 0px 0px;
}
.scrollbar {
    position: absolute;
    bottom: 20px;
    width: 56px;
    height: 56px;
    background: url(../imgs/page/homepage4/scrollbar.png) no-repeat center;
    background-size: contain;
    left: 0px;
    right: 0px;
    margin: auto;
}
.list-partners-left {
    display: flex;
    flex-wrap: wrap;
    li {
        padding: 10px 30px 10px 0px;
        margin-bottom: 15px;
        width: 12.5%;
        img {
            max-height: 40px;
        }
    }
}
.mw-80 {
    position: relative;
    max-width: 80%;
    .image-4 {
        bottom: 40px;
        right: -80px;
    }
}
.img-round-top {
    border-top-left-radius: 500px;
    border-top-right-radius: 500px;
    display: block;
}
.none-bd-bottom {
    .border-bottom {
        border-bottom: 0px !important;
        display: none;
    }
}
.box-cover-video-revert {
    background-position: bottom left;
    background-size: 100px;
    .box-info-video {
        padding: 0px 30px 0px 60px;
    }
}