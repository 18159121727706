.banner-contact {
    background: linear-gradient(360deg, rgba(205, 226, 231, 0.44) 0%, rgba(205, 226, 231, 0) 100%);
    position: relative;
    padding: 110px 0px 150px 0px;
    .banner-1 {
        padding: 0px;
    }
}
.box-banner-contact {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 50%;
    overflow: hidden;
    text-align: right;
    background: url(../imgs/page/contact/bg-banner.png) no-repeat top center;
    background-size: contain;
    padding-top: 30px;
    img {
        display: block;
    }
}
.box-form-contact {
    border: 1px solid $color-gray-80;
    border-radius: 16px;
    padding: 40px 40px;
    .form-control {
        font-size: 16px;
        line-height: 24px;
    }
}
.card-contact {
    border: 0px;
    padding: 0px;
    .card-image {
        max-width: 69px;
        margin-right: 15px;
        text-align: left;
    }
    &:hover {
        box-shadow: none;
        border: 0px;
    }
}
textarea.textarea-control {
    height: auto;
    min-height: 150px;
}
.icon-user {
    background-image: url(../imgs/page/contact/user.svg);
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 40px;
}
.icon-email {
    background-image: url(../imgs/page/contact/email.svg);
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 40px;
}
.icon-phone {
    background-image: url(../imgs/page/contact/phone.svg);
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 40px;
}
.icon-company {
    background-image: url(../imgs/page/contact/company.svg);
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 40px;
}