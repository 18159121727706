.box-banner-shop-list {
    padding: 0px;
}
.box-banner-image {
    display: inline-block;
    width: 100%;
    padding: 32px;
}
.mw-90 {
    max-width: 90%;
}
.list-buttons-round {
    li {
        a {
            border-radius: 50px;
        }
    }
}
.box-info-banner-shop-list {
    padding: 5px 32px;
}
.box-quantity {
    display: flex;
    .form-quantity {
        max-width: 95px;
        background-color: $background-white;
        border-radius: 4px;
        height: 50px;
        position: relative;
        width: 95px;
        overflow: hidden;
        border: 1px solid $color-gray-80;
        input {
            height: 50px;
            border: 0px;
            padding:7px 20px 10px 25px;
            line-height: 34px;
            font-size: 26px;
            color: $color-brand-1;
            font-weight: bold;
            width: 100%;
        }
        .button-quantity {
            position: absolute;
            top: 3px;
            right: 5px;
            height: 20px;
            width: 40px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
        }
        .button-up {
            background-image: url(../imgs/page/shop-list/up.svg);
        }
        .button-down {
            background-image: url(../imgs/page/shop-list/down.svg);
            top: auto;
            bottom: 3px;
        }
    }
}